@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400italic,600,700,700italic,800,900");

:root {
  --app-bottom-padding: 140px;
  --app-top-padding: 80px;
  --app-width-xs: 600px;
  --app-width-lg: 1200px;
  --body-bg: hsla(0, 0%, 95.7%, 1);
  --border-radius: 8px;
  --border-radius-button: 30px;
  --border-radius-sm: 4px;
  --box-bg: hsl(0, 0%, 100%);
  --box-bg--shade: hsl(0, 0%, 95.7%);
  --box-bg--transparent-blur: hsla(0, 0%, 100%, 0.75);
  --box-bg--transparent: hsla(0, 0%, 100%, 0.95);
  --box-shadow-contrast: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --box-shadow-large: 0px 15px 25px hsla(0, 0%, 0%, 0.15),
    0 5px 10px hsla(0, 0%, 0%, 0.1);
  --box-shadow-medium: 0px 10px 20px hsla(0, 0%, 0%, 0.15),
    0 3px 6px hsla(0, 0%, 0%, 0.1);
  --box-shadow-miniscule: 0 1px 2px 0 hsla(0, 0%, 0%, 0.1),
    0 1px 1px 0 hsla(0, 0%, 0%, 0.05);
  --box-shadow-small: 0px 3px 6px hsla(0, 0%, 0%, 0.1),
    0 2px 4px hsla(0, 0%, 0%, 0.1);
  --box-shadow-tiny: 0px 1px 3px hsla(0, 0%, 0%, 0.1),
    0 1px 2px hsla(0, 0%, 0%, 0.1);
  --color-alert: hsl(7.6, 97.5%, 52.2%);
  --color-border--dark: hsl(0, 0%, 80%);
  --color-border--darker: hsl(0, 0%, 77.6%);
  --color-border--darkest: hsl(0, 0%, 56.5%);
  --color-border--light: hsl(0, 0%, 93.3%);
  --color-border--lighter: hsl(0, 0%, 93.7%);
  --color-border--medium: hsl(0, 0%, 89.8%);
  --color-border--neutral: hsl(0, 0%, 84.7%);
  --color-border--subtle: hsla(0, 0%, 0%, 0.1);
  --color-button--primary: hsla(0, 0%, 100%);
  --color-cta--secondary: hsl(211.3, 100%, 50%);
  --color-cta-hsl: 211.3, 100%, 50%;
  --color-cta: hsl(211.3, 100%, 50%);
  --color-input-border: hsl(0, 0%, 56.5%);
  --color-ok: hsl(112.8, 60.3%, 59.4%);
  --color-secondary: hsl(211.3, 100%, 96.7%);
  --color-secondary-dark: hsl(211.3, 100%, 93.9%);
  --color-text: hsl(0, 0%, 20%);
  --header-bg--transparent: hsla(0, 0%, 100%, 0.75);
  --header-bg: hsla(0, 0%, 100%, 1);
  --header-border: hsla(0, 0%, 90%, 1);
  --navbar-height: 60px;
  --notice-background: hsl(51.7, 93.3%, 88.1%);
  --notice-border: hsl(51.8, 92.3%, 73.9%);
  --notice-color: hsl(0, 0%, 20%);
  --placeholder-color: hsl(0, 0%, 90%);
  --quote-background: hsl(0, 0%, 98.4%);
  --subtle-background-hsl: 0, 0%, 98%;
  --subtle-background: hsl(0, 0%, 98%);
  --subtle-border: hsl(0, 0%, 96.5%);
  --text-grey: hsl(0, 0%, 56.5%);
  --text-grey--dark: hsl(0, 0%, 45.9%);
  --theme: light;
  --tooltip-bg: hsla(0, 0%, 0%, 0.8);
  --tooltip-color: hsl(0, 0%, 100%);
  --transition-time: 250ms;
  /* --color-input-bg: hsl(211.3, 100%, 96.7%); */
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-bg: hsla(0, 0%, 8%, 1);
    --box-bg: hsl(0, 0%, 12%);
    --box-bg--shade: hsl(0, 0%, 8.1%);
    --box-bg--transparent-blur: hsla(0, 0%, 12%, 0.75);
    --box-bg--transparent: hsla(0, 0%, 12%, 0.95);
    --box-shadow-contrast: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    --box-shadow-large: 0px 15px 25px hsla(0, 0%, 0%, 0.65),
      0 5px 10px hsla(0, 0%, 0%, 0.6);
    --color-border--dark: hsl(0, 0%, 20%);
    --color-border--darker: hsl(0, 0%, 20.6%);
    --color-border--light: hsl(0, 0%, 25%);
    --color-border--medium: hsla(0, 0%, 20%, 1);
    --color-border--neutral: hsla(0, 0%, 30%, 1);
    --color-border--subtle: hsla(0, 0%, 100%, 0.15);
    --color-cta: hsl(211.3, 100%, 40%);
    --color-cta-hsl: 211.3, 100%, 40%;
    --color-cta--secondary: hsl(211.3, 100%, 70%);
    --color-input-border: hsl(0, 0%, 35%);
    --color-secondary: hsl(211.3, 100%, 21.7%);
    --color-secondary-dark: hsl(211.3, 100%, 24.5%);
    --color-text: hsla(0, 0%, 93%, 1);
    --header-bg: hsla(0, 0%, 10%, 1);
    --header-bg--transparent: hsla(0, 0%, 13%, 0.75);
    --header-border: hsla(0, 0%, 15%, 1);
    --notice-background: hsl(51.7, 93.3%, 10.1%);
    --notice-border: hsl(51.8, 92.3%, 13.9%);
    --notice-color: hsl(0, 0%, 100%);
    --placeholder-color: hsl(0, 0%, 15%);
    --quote-background: hsl(0, 0%, 10%);
    --subtle-background-hsl: 0, 0%, 15%;
    --subtle-background: hsl(0, 0%, 15%);
    --subtle-border: hsla(0, 0%, 19%, 1);
    --text-grey: hsla(0, 0%, 55%, 1);
    --text-grey--dark: hsla(0, 0%, 40%, 1);
    --theme: dark;
    --tooltip-bg: hsla(0, 0%, 100%, 0.9);
    --tooltip-color: hsl(0, 0%, 0%);
  }
}

@media (min-width: 1001px) {
  :root {
    --app-top-padding: 100px;
    --app-bottom-padding: 60px;
  }
}

body {
  background: var(--body-bg);
  color: var(--color-text);
  margin: 0;
  padding: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--color-cta);
}
