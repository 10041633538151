.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}

.react-datepicker {
  background-color: var(--box-bg);
  box-shadow: var(--box-shadow-large);
  border-color: var(--color-border--dark);
  color: var(--color-text);
  font-family: inherit;
}

.react-datepicker__header {
  background-color: var(--box-bg--shade);
  border-bottom: 0;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  border-top-color: var(--color-border--dark);
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: var(--color-border--dark);
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom-start"]
  .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top-start"]
  .react-datepicker__triangle {
  left: 50% !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--color-text);
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  color: var(--color-text);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: var(--box-bg--shade);
}

.react-datepicker__day--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  color: var(--box-bg);
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-text--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: var(--brand-primary);
  color: var(--box-bg);
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: var(--box-bg);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__close-icon {
  top: 50%;
  transform: translateY(-50%);
}
