:root {
  --brand-primary: #f8b233;
  --brand-primary--hover: #ffc152;
  --brand-logo-theme: #333;
  --brand-header-bg: #fff;
  --brand-header-color: #333;
  --color-Mastermind: #f3b944;
  --color-Skillshare: #7f7038;
  --color-Other: #bfa245;
  --signet-height: 18px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --brand-logo-theme: #fff;
  }
}
