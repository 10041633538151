.App {
  min-height: 100vh;
}

h1 {
  margin: 0 0 20px;
}

h2,
h3 {
  /* margin: 0 0 15px; */
}

p {
  line-height: 1.25;
}

p a {
  color: var(--color-cta);
}

.swiper-container {
  overflow: visible !important;
}

.swiper-slide {
  display: flex;
  height: auto !important;
  opacity: 0.5;
}

.swiper-slide-active {
  opacity: 1;
}

.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -30px !important;
}

.Toastify__toast-body {
  word-break: break-word;
}
